import Vue from 'vue';
import Vuex from 'vuex';
import product from '@/store/product';
import order from '@/store/order';
import customer from '@/store/customer';
import promotion from '@/store/promotion';
import setting from '@/store/setting';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    langcode: 'zh',
    isLogin: false,
    adminUrl: process.env.VUE_APP_ADMIN_URL,
    backendUrl: process.env.VUE_APP_BACKEND_URL,
    apiUrl: process.env.VUE_APP_API_URL,
  },
  mutations: {
    setLang(nextState, langcode){
      nextState.langcode = langcode;
    },
    setLoginStatus(nextState, loginStatus){
      nextState.isLogin = loginStatus;
    },
  },
  actions: {
    async changeLang(context, langcode){
      context.commit('setLang', langcode);
    },
    async startLogin(context){
      context.commit('setLoginStatus', true);
    },
    async startLogout(context){
      context.commit('setLoginStatus', false);
    },
  },
  modules: {
    product,
    order,
    customer,
    promotion,
    setting
  }
})
